#input-field {
    background-color: #ececec;
    border: 2px solid var(--dark-red);
    padding: 10px;
    font-size: 16px;
    text-align: center;
    width: 80%;
}

#button {
    background-color: var(--dark-red);
    height: 100%;
    width: 100%;
}

#button:hover {
    background-color: var(--chestnut);
    color: black;
}

#loginbutton {
    background-color: var(--dark-red);
    height: 100%;
    width: 100%;
}

#loginbutton:hover {
    background-color: var(--chestnut);
    color: black;
}