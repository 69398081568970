.r-fullview {
    height: 100vh;
    width: 100%;
}

.r-maincontent-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 90%;
    background-image: url('../icons/HintergrundReg.png');
    background-size: cover;
    background-position: center;
    text-align: center;
}

.r-maincontent_container {
    position: relative;
    top: 10%;
    width: 90%;
    max-width: 78vh;
    max-height: calc(100% - 10vh);
    display: inline-block;
    background-color: rgba(236, 236, 236, 0.5);
    border-radius: 14px;
    border-style: solid;
    border-color: #000;
    overflow: auto;
}

.r-header {
    width: 82%;
    margin-top: 2%;
}

.inputField_container {
    margin-top: 0.5%;
    margin-bottom: 0.5%;
}

.r-button_container {
    width: 70%;
    display: inline-block;
    margin-top: 1vh;
    margin-bottom: 5%;
}

.r-loginbutton_container {
    height: 4vh;
    margin-top: 2%;
    margin-bottom: 2%;
    min-height: 32px;
    min-width: 130px;
}