.main-content_container {
    position: absolute;
    top: 0;
    right: 0;
    height: 90vh;
    width: 80%;
    display: flex;
    flex-direction: column;
    
}

.profile-header {
    position: absolute;
    top: 0;
    width: 100%;
    height: 10%;
}

.profile-content_container {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap:5%;
}

.profile-content-s_container {
    width: 42.5%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 10%;
}

.top_container {
    height: 45%;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10%;
}

.bottom_container {
    height: 45%;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 10%;
}

.stat_container {
    height: 100%;
    width: 45%;
    display: flex;
    flex-direction: column;
    border-radius: 14px;
    box-shadow: 8px 8px 8px var(--dark-red-light);
}

.name_container {
    height: 30%;
    width: 100%;
}

.number_container {
    height: 70%;
    width: 100%;
    display:flex;
    align-items: center;
}

.profile-text {
    margin-left: 10%;
}

.profile-text-prename {
    margin-left: 5%;
    display:inline;
}
.profile-text-surname {
    display:inline;
}