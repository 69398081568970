.pdfviewer {
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 90%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pdfviewer_container {
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    height: 90%;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}