.fullview {
    height: 100vh;
    width: 100%;
}

.maincontent-background {
    position: absolute;
    top: 0;
    width: 100%;
    height: 90%;
    background-image: url('../icons/Background.jpg');
    background-size: cover;
    background-position: center;
    text-align: center;
}

.maincontent_container {
    position: relative;
    top: 10%;
    width: 90%;
    max-width: 78vh;
    max-height: calc(100% - 10vh);
    display: inline-block;
    background-color: rgba(236, 236, 236, 0.5);
    border-radius: 14px;
    border-style: solid;
    border-color: #000;
    overflow: auto;
}

.header {
    width: 82%;
    margin-top: 2%;
}

.mainicon__container-big {
    margin-top: 1vh;
    margin-bottom: 4%;
}

.mainicon {
    width: 70%;
    max-width: 38vh;
}

.inputField_container {
    margin-top: 0.5%;
    margin-bottom: 0.5%;
}

.button_container {
    width: 70%;
    display: inline-block;
    margin-top: 1vh;
    margin-bottom: 5%;
}

.loginbutton_container {
    height: 4vh;
    margin-top: 2%;
    margin-bottom: 2%;
    min-height: 32px;
    min-width: 130px;
}

.test {
    position: absolute;
    top: 92.5%;
    width: 100%;
}

.login-sidebar {
    background-color: var(--dark-red);
    height: 90%;
    width: 20%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.icon_container {
    width: 100%;
    height: 30%;
    display:flex;
    justify-content: center;
    align-items: center;
}

.arrow {
    height: 50%;
    width: 50%;
}

.logo {
    width: 80%;
}