.gender-content_container {
    height: 90%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5%;
    background-image: url('../icons/HintergrundReg.png');
    background-size: cover;
    background-position: center;
    text-align: center;

}

.bubble_container {
    width: 100%;
    height: 20%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.bubble {
    width: 85%;
    height: 50%;
    box-shadow: 8px 8px 8px var(--dark-red-light);
    border-radius: 14px;
    background-color: white;
}

.bubble:after {
    content: '';
    width: 0;
    height: 0;
    border: 24px solid transparent;
    border-top-color: #fff;
    border-bottom: 0;
    margin-left: -90%;
}

.bubble-text_container {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.logo_container {
    position: absolute;
    left: 0;
    width: 20%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.gender-logo {
    width: 100%;
}

.selection_container {
    height: 40%;
    width: 60%;
    gap: 5%;
    display: flex;
    flex-direction: row;
}

.gender-icons_container {
    height: 100%;
    width: 47.5%;
    justify-content: center;
    display: flex;
    align-items: center;
}



.gender-icon {
    max-width: 100%;
    max-height: 100%;
}

.ww-button_container {
    height: 10%;
    width: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5%;
}

.ww-button {
    width: 30%;
    height: 50%;
    background-color: var(--dark-red)
}

.ww-button:hover {
    background-color: var(--chestnut);
    color: black;
}


/* birthday view */

.interaction_container {
    height: 40%;
    width: 60%;
    gap: 5%;
    display: flex;
    flex-direction: column;
}

.birthday-selector_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 70%;
    gap: 5%;
}

.selector_container {
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 100%;
    gap: 10%;
}

.selector {
    height: 60%;
    width: 100%;
}

.select {
    width: 100%;
    height: 100%;
    background-color: rgba(236, 236, 236, 0.8);
    text-align: center;
}

.label_container {
    height: 30%;
    width: 100%;
}

.welcomeworkflow-error-message {
    width: 100%;
    height: 15%;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* bodydata */

.bodydata_container {
    height: 40%;
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10%;
}

.data-row_container {
    display: flex;
    width: 80%;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10%;
}

.data-label {}

/* calories view */

.calories_container {
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

/* goal view */

.goal_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    height: 80%;
    gap: 5%;
}

.specific-goal_container {
    height: 100%;
    width: 30%;
    gap: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.goal-image_container {
    width: 100%;
    height: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.goal-image {
    height: 100%;
}

.goal-label_container {
    background-color: #ececec;
    border-radius: 14px;
    height: 20%;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.goal-label {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    top: 25%;
}