@import './style.css';

@font-face {
    font-family: "Aileron-Regular";
    font-style: normal;
    font-weight: 500 ;
    src: url("https://fonts.animaapp.com/Aileron-Regular")
    format(truetype);
}

@font-face {
    font-family: "Aileron-Bold";
    font-style: normal;
    font-weight: 700;
    src: url("https://fonts.animaapp.com/Aileron-Bold")
    format(truetype);
}

@media (max-width: 700px) {
    .footer .aileron-bold-white-16px {
        font-size: 9px;
    }
}

.footer-background {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 10vh;
    width: 80%;
    background-color: var(--chestnut);
}

.footer-background-long {
    position: absolute;
    top: 90%;
    height: 10%;
    width: 100%;
    background-color: var(--chestnut);
}

.footer-button__container {
    height: 10vh;
    width: 100%;
    display: flex;
    flex-direction: row;
}
.footer-button {
    height: auto;
    width: 25%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.footer-button:hover {
    background-color: #883938;
}
