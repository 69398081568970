@import './style.css';

@font-face {
    font-family: "Aileron-Bold";
    font-style: normal;
    font-weight: 700;
    src: url("https://fonts.animaapp.com/Aileron-Bold") format(truetype);
}

@media (max-width: 1000px) {
    .sidebar-string {
        display: none;
    }
}

.sidebar-background {
    height: 100vh;
    width: 20%;
    background-color: #990100;
}

.sidebar-mainicon__container {
    position: absolute;
    height: 30vh;
    width: 20%;
}

.sidebar-mainicon {
    position: relative;
    left: 50%;
    top: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    height: auto;
    width: 80%;
}

.sidebar-menu {
    position: absolute;
    bottom: 10vh;
    align-items: center;
    justify-content: space-evenly;
    align-self: flex-end;
    display: flex;
    flex-direction: column;
    height: 60vh;
    width: 20%;
}

.sidebar-button {
    position: relative;
    width: 100%;
    height: 10vh;
    display: flex;
    flex-direction: row;
}

.sidebar-button:hover {
    background-color: #700000;
}

.sidebar-icon__container {
    position: absolute;
    left: 10%;
    width: 10vh;
    height: 100%;
}

.sidebar-icon {
    position: relative;
    left: 25%;
    top: 25%;
    height: 35px;
    width: 35px;
}

.sidebar-string {
    position: absolute;
    left: 40%;
    top: 40%;
}