.main-content_container {
    position: absolute;
    top:0;
    right:0;
    height: 90vh;
    width: 80%;
}


.text_container {
    position: relative;
    display: flex;

    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 88%;
    width: 75%;
    scroll-behavior: auto;
    overflow: auto;
}

.text-header {
    text-align: center;
}

.text {
    text-align: justify;

}